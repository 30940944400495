import { observable, action } from 'mobx';
import { AuthConfig } from 'Services/AuthorizationConfigService';

class AuthorizationConfigStore {
    @observable authConfig?: AuthConfig | null = null;

    constructor() {
        if (sessionStorage.authConfig) {
            this.authConfig = JSON.parse(sessionStorage.authConfig);
        }
    }

    @action
    setConfig(config: AuthConfig): void {
        this.authConfig = config;
        sessionStorage.authConfig = JSON.stringify(config);
    }

    @action
    clearConfig(): void {
        this.authConfig = null;
        sessionStorage.authConfig = undefined;
    }
}

export default AuthorizationConfigStore;
