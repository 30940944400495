export const toE164 = (phoneNumber?: string, extension?: string): string => {
    if(!phoneNumber) {
        return ''
    }
    const noSpace = phoneNumber.replace(/\s+/g, "");
    const noHyphen = noSpace.replaceAll("-", "");
    const noParentheses = noHyphen.replaceAll("(", "").replaceAll(")", "");
    const withExtension = noParentheses.concat(extension ? ` x${extension}` : '');
    return withExtension;
}

export const fromE164 = (phoneNumber: string): {number?: string, ext?: string} => {
    const noSpace = phoneNumber.replace(/\s+/g, '');
    const split = noSpace.split('x');
    return { number: split[0], ext: split[1] };
}