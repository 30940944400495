import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ClockIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M13.9912 22.7422C18.9746 22.7422 23.0879 18.6289 23.0879 13.6543C23.0879 8.67969 18.9658 4.56641 13.9824 4.56641C9.00781 4.56641 4.90332 8.67969 4.90332 13.6543C4.90332 18.6289 9.0166 22.7422 13.9912 22.7422ZM13.9912 20.9316C9.95703 20.9316 6.73145 17.6885 6.73145 13.6543C6.73145 9.62012 9.95703 6.38574 13.9824 6.38574C18.0166 6.38574 21.2598 9.62012 21.2686 13.6543C21.2773 17.6885 18.0254 20.9316 13.9912 20.9316ZM9.56152 14.7617H13.9824C14.3867 14.7617 14.6943 14.4541 14.6943 14.0586V8.31055C14.6943 7.91504 14.3867 7.60742 13.9824 7.60742C13.5957 7.60742 13.2881 7.91504 13.2881 8.31055V13.3555H9.56152C9.16602 13.3555 8.8584 13.6631 8.8584 14.0586C8.8584 14.4541 9.16602 14.7617 9.56152 14.7617Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default ClockIcon;
