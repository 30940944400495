import React, { FunctionComponent } from 'react';
import { Divider as AntDivider } from 'antd';
import './index.less';

interface DividerProps {
    marginTop?: number;
    marginBottom?: number;
    className?: string;
}

const Divider: FunctionComponent<DividerProps> = ({ marginBottom, marginTop, className }) => {
    return (
        <div
            className={`Divider ${className ?? ''}`}
            style={{ marginBottom: marginBottom, marginTop: marginTop }}
        >
            <AntDivider />
        </div>
    );
};

export default Divider;
