import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const MenuIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <rect x="4.5" y="7.65991" width="19" height="1.84722" rx="0.923612" fill={fill} />
                <rect x="4.5" y="13.0696" width="19" height="1.84722" rx="0.923612" fill={fill} />
                <rect x="4.5" y="18.4795" width="19" height="1.84722" rx="0.923612" fill={fill} />
            </svg>
        </span>
    );
};

export default MenuIcon;
