// GENERATED FILE - DO NOT MODIFY
import { AuthConfigDto } from 'Api/Features/Authorization/Dtos/AuthConfigDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class AuthorizationProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getAuthConfig(): Promise<AuthConfigDto | null> {
        const uri = this.buildUri(
            "/auth-config",
            null,
            null
        );

        const data: AuthConfigDto | null = await this.httpClient.get<AuthConfigDto | null>(uri);
        return data;
    }
}