import React, { FunctionComponent } from 'react';
import './index.less';

interface WhiteCardProps {
    padding?: number;
    paddingTop?: number;
    paddingBottom?: number;
    paddingRight?: number;
    paddingLeft?: number;
    width?: number;
    heigth?: number;
    className?: string;
    style?: React.CSSProperties;
}

const WhiteCard: FunctionComponent<WhiteCardProps> = ({
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    width,
    heigth,
    className,
    children,
    style,
}) => {
    return (
        <div
            className={`WhiteCard ${className ? className : ''}`}
            style={{
                paddingTop: paddingTop ?? padding,
                paddingBottom: paddingBottom ?? padding,
                paddingRight: paddingRight ?? padding,
                paddingLeft: paddingLeft ?? padding,
                width: width,
                height: heigth,
                ...style,
            }}
        >
            {children}
        </div>
    );
};

export default WhiteCard;
