import React, { FunctionComponent } from 'react';
import './rich-text-display.less';

interface RichTextDisplayProps {
    className?: string;
    html?: string | null;
}
export const RichTextDisplay: FunctionComponent<RichTextDisplayProps> = ({ className, html }) => {
    return (
        <div
            className={`RichTextDisplay ${className ?? ''}`}
            dangerouslySetInnerHTML={{
                __html: html ?? '',
            }}
        />
    );
};
