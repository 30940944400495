export enum ImgSize {
    s = 50,
    m = 150,
    l = 350,
    xl = 600,
}

export const img = (url: string, width: number, square = true): string => {
    const height = square ? `,h-${width * 2}` : '';
    const param = `?tr=w-${width * 2}${height}`;

    return url + param;
};
